import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { getAdvancedFilter } from '../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { fetchWatchListsAction } from '../../store/watchList/actions'
import BondList from '../BondList/BondList'

const OpenFinWatchlist = () => {
  const { gridIndex } = useParams()
  const dispatch = useAppDispatch()
  const filter = useAppSelector(getAdvancedFilter)(Number(gridIndex))

  useEffect(() => {
    dispatch(fetchWatchListsAction(Number(gridIndex)))
  }, [dispatch, gridIndex])

  if (!filter) return <></>

  return <BondList gridIndex={Number(gridIndex)} />
}

export default OpenFinWatchlist
