import cx from 'classnames'
import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import BondList from '../containers/BondList/BondList'
import DepthOpener from '../containers/Openfin/DepthOpener'
import { useOpenFin } from '../helpers/hooks/useOpenFin'
import { getActivityPanelVisible } from '../store/activity/selectors'
import { unsubscribeGetSecurities } from '../store/securities/actions'
import { getGridIndices } from '../store/securities/selectors'
import { areSettingsLoaded } from '../store/settings/selectors'
import { useAppSelector } from '../store/types'
import { getDropdownState } from '../store/upload/selectors'
import ActivityWrapper from './Activity/ActivityWrapper'
import styles from './SecuritiesTable.module.scss'
import EnhancedUploadDropdownMenu from './Upload/EnhancedUpload/EnhancedUploadDropdownMenu'

const SecuritiesTable = () => {
  const { openPlatformWindow, isOpenFin } = useOpenFin()
  const gridIndices = useAppSelector(getGridIndices)
  const activityPanelVisible = useAppSelector(getActivityPanelVisible)
  const settingsLoaded = useAppSelector(areSettingsLoaded)
  const dropdownState = useAppSelector(getDropdownState)(0)
  const dispatch = useDispatch()
  const gridsToDraw = useMemo(() => {
    return isOpenFin ? [gridIndices[0]] : gridIndices
  }, [isOpenFin, gridIndices])

  useEffect(() => {
    return () => {
      dispatch(unsubscribeGetSecurities())
    }
  }, [dispatch])

  useEffect(() => {
    if (isOpenFin) {
      const [, ...indices] = gridIndices
      void Promise.all(
        indices.map((index) => openPlatformWindow(`Watchlist/${index}`))
      )
    }
  }, [gridIndices, isOpenFin, openPlatformWindow])

  return (
    <div className={styles.wrapper}>
      <div
        className={
          isOpenFin
            ? styles.finSecuritiesWrapper
            : cx(
                styles.securitiesWrapper,
                activityPanelVisible || styles.activitiesHidden
              )
        }
      >
        <div className={styles.panelWrapper}>
          {gridsToDraw.map((gridIndex) => (
            <div
              key={gridIndex}
              className={cx(
                styles.bondListWrapper,
                isOpenFin && styles.finWrapper
              )}
            >
              <BondList gridIndex={gridIndex} />
            </div>
          ))}
          <DepthOpener />
          {dropdownState !== 'closed' && (
            <EnhancedUploadDropdownMenu
              gridIndex={0}
              currentState={dropdownState}
            />
          )}
        </div>
      </div>
      {settingsLoaded && <ActivityWrapper />}
    </div>
  )
}

export default memo(SecuritiesTable)
