import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDebounce } from 'react-use'
import ComponentHeader from '../../components/ComponentHeader/ComponentHeader.tsx'
import Loading from '../../components/Loading/Loading'
import TradeListConfirmModal from '../../components/TradeListConfirmModal/TradeListConfirmModal'
import { setListTradingWatchlistId } from '../../store/listTrading/actions'
import { areSettingsLoaded } from '../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { fetchWatchlistDetails } from '../../store/watchList/actions'
import {
  getDetailsCallState,
  getWatchlistDetails
} from '../../store/watchList/selectors'
import Grid from './Grid'

import FinHeaderButtons from '../../components/ComponentHeader/helpers.tsx'
import gridStyles from '../../components/Grid/grid.module.scss'
import { useOpenFin } from '../../helpers/hooks/useOpenFin.tsx'
import styles from './grid.module.scss'

const TradeWatchList = () => {
  const { finWindowAction, isOpenFin } = useOpenFin()
  const [drawConfirm] = useState(true)
  const dispatch = useAppDispatch()

  const { watchlistId } = useParams<'watchlistId'>()
  const watchlist = useAppSelector(getWatchlistDetails)(Number(watchlistId))

  useEffect(() => {
    watchlistId && dispatch(fetchWatchlistDetails(Number(watchlistId), -1))
  }, [watchlistId, dispatch])

  const settingsAreLoaded = useAppSelector(areSettingsLoaded)

  const closeTradeConfirm = useCallback(() => {
    dispatch(setListTradingWatchlistId('invalidWatchlist'))

    // Openfin specific
    finWindowAction(`ListTrading/${watchlistId}`, 'close')
  }, [isOpenFin, dispatch, finWindowAction, watchlistId])

  const loadingState = useAppSelector(getDetailsCallState)
  const loaded = watchlistId && !loadingState.pending && !loadingState.error
  useDebounce(
    () => {
      loaded && dispatch(setListTradingWatchlistId(Number(watchlistId)))
    },
    200,
    [loaded]
  )

  const showGrid =
    loaded &&
    watchlist?.id &&
    settingsAreLoaded &&
    watchlist?.securityIds.length <= 200

  return (
    <>
      <div className={cx(gridStyles.outerGridContainer, styles.listTrading)}>
        <ComponentHeader
          title={`Trade From List: ${watchlist?.name ?? ''}`}
          headerButtons={
            <FinHeaderButtons title={`ListTrading/${watchlistId}`} />
          }
        />
        {loadingState.pending && <Loading />}
        {loadingState.error && (
          <div className={styles.invalidWatchlist}>
            This watchlist is not available. {loadingState.status}
          </div>
        )}
        {showGrid && (
          // @ts-ignore
          <Grid watchlistId={watchlist.id} />
        )}
      </div>
      {watchlist && drawConfirm && (
        <TradeListConfirmModal
          watchlist={watchlist}
          closeConfirm={closeTradeConfirm}
        />
      )}
    </>
  )
}

export default TradeWatchList
