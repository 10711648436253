import { faExternalLinkAlt } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useOpenFin } from '../../../helpers/hooks/useOpenFin'
import { popoutSecurity } from '../../../store/depthOfMarket/actions'
import { getPopoutSecurityIds } from '../../../store/depthOfMarket/selectors'
import { useAppDispatch, useAppSelector } from '../../../store/types'

export const PopoutDepthIcon = ({ securityId }: { securityId: number }) => {
  const dispatch = useAppDispatch()
  const { isOpenFin, finWindowAction } = useOpenFin()
  const popoutIds = useAppSelector(getPopoutSecurityIds)

  const handleClick = useCallback(() => {
    if (isOpenFin && popoutIds.includes(securityId)) {
      finWindowAction(`Depth/${securityId}`, 'restore')
    } else {
      dispatch(popoutSecurity(securityId))
    }
  }, [finWindowAction, isOpenFin, popoutIds, securityId, dispatch])

  return (
    <span data-testid={`popout-${securityId}`} onClick={handleClick}>
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        title="Popout Depth for this Security"
      />
    </span>
  )
}
