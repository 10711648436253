import React, { useCallback } from 'react'
import { Item, Menu, Separator, useContextMenu } from 'react-contexify'
import { useDispatch } from 'react-redux'
import { formatMaturityDate } from '../../helpers/formatting'
import useBroadcastChannel from '../../helpers/hooks/useBroadcastChannel'
import { showSecurityDetails } from '../../store/securities/actions'
import { SecurityStaticData } from '../../store/securities/reducer'
import styles from './Title/Header.module.scss'

interface WrapperProps {
  gridIndex?: number
  instance?: string
  security: SecurityStaticData
}

interface SecuritySummaryProps extends WrapperProps {
  copyToClipboard: (textToCopy: string) => void
}

export const InternalSecuritySummary = ({
  security,
  instance = 'grid',
  gridIndex = 0,
  copyToClipboard
}: SecuritySummaryProps) => {
  const sendMessage = useBroadcastChannel('broadcastchannel')
  const { coupon, issuerSymbol, maturityDate, cusip, isin } = security
  const menuId = `${security.id}-summary-menu-${instance}`

  const identifier = cusip || isin || ''

  const dispatch = useDispatch()
  const { hideAll, show } = useContextMenu({ id: menuId })

  const copyCusip = useCallback(() => {
    copyToClipboard(identifier)
    hideAll()
  }, [identifier])

  const showDetails = useCallback(() => {
    dispatch(showSecurityDetails(gridIndex, security.id))
    sendMessage({ action: showSecurityDetails(gridIndex, security.id) })
  }, [gridIndex, security.id])

  return (
    <>
      <div
        data-testid={`${security.id}-summary`}
        onContextMenu={(e) => show({ event: e })}
        className={styles.securitySummary}
      >
        <span>{issuerSymbol}</span>
        <span>{coupon}</span>
        <span>{formatMaturityDate(maturityDate, 'normalGray')}</span>
        <span>| {identifier}</span>
      </div>
      <Menu id={menuId} className={styles.menuStyle}>
        <Item onClick={copyCusip}>Copy CUSIP</Item>
        <Separator />
        <Item onClick={showDetails}>View Security Details</Item>
      </Menu>
    </>
  )
}

InternalSecuritySummary.displayName = 'SecuritySummary'
