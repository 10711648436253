import { GetRowIdFunc, GridApi, GridReadyEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { faDownload } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { tradeBlotterFetch } from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'
import { Trade } from '../../../store/activityTradeBlotter/types'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { getCurrentTheme } from '../../../store/userPreferences/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import FinHeaderButtons from '../../ComponentHeader/helpers'
import gridStyles from '../../Grid/grid.module.scss'
import { columnDefinitions } from './columnDefs'

const getTradeRowId: GetRowIdFunc<Trade> = ({ data }) => {
  return `${data?.id ?? ''}`
}

const ActivityTradeBlotterGrid: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)

  const tradeData = useAppSelector(getActivityTradeBlotter)
  const tradeDataError = useAppSelector(getActivityTradeBlotterError)
  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  const onGridReady = useCallback(({ api }: GridReadyEvent) => {
    if (!gridApi) {
      setGridApi({ api })
      api.autoSizeAllColumns()
    }
  }, [])

  if (tradeDataError) {
    return <div>{tradeDataError}</div>
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Trade Blotter"
        headerButtons={<FinHeaderButtons title="ActivityBlotter" />}
      />
      <div className={gridStyles.optionsContainer}>
        <button
          onClick={() => {
            gridApi?.api.exportDataAsCsv({})
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
          &nbsp;Export
        </button>
      </div>
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <AgGridReact
          rowData={tradeData}
          columnDefs={columnDefinitions}
          columnMenu="legacy"
          onGridReady={onGridReady}
          overlayNoRowsTemplate={'No trades found.'}
          overlayLoadingTemplate="Loading Trades..."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowId={getTradeRowId}
        />
      </div>
    </div>
  )
}

export default ActivityTradeBlotterGrid
