import { Action } from 'redux'

export interface WsConnectAction {
  type: 'ws.connect'
}

export interface WsConnectSuccessAction {
  type: 'ws.connect-success'
}

export interface WsConnectFailureAction {
  type: 'ws.connect-failure'
  payload: any
}

export interface AdjustServerTimeDelayAction {
  type: 'ws.adjust-server-time'
}

export interface AdjustServerTimeDelaySuccessAction {
  type: 'ws.adjust-server-time-success'
  payload: { delayMs: number }
}

export const connect = (): WsConnectAction => ({
  type: 'ws.connect'
})

export const connectSuccess = (): WsConnectSuccessAction => ({
  type: 'ws.connect-success'
})

export const connectFailure = (err: any): WsConnectFailureAction => ({
  type: 'ws.connect-failure',
  payload: err
})

export const adjustServerTimeDelay = (): AdjustServerTimeDelayAction => ({
  type: 'ws.adjust-server-time'
})

export const adjustServerTimeDelaySuccess = (
  delayMs: number
): AdjustServerTimeDelaySuccessAction => ({
  type: 'ws.adjust-server-time-success',
  payload: { delayMs }
})

export interface LogErrorAction {
  type: 'ws.log-error'
  payload: any
}

export const logError = (error: any): LogErrorAction => ({
  type: 'ws.log-error',
  payload: error
})

export interface ForceReloadAction extends Action {
  type: 'ws.force-reload'
}

export const forceReload = (): ForceReloadAction => ({
  type: 'ws.force-reload'
})

export interface LogServerMetadataAction extends Action {
  type: 'ws.log-server-metadata'
}

export const logServerMetadata = (): LogServerMetadataAction => ({
  type: 'ws.log-server-metadata'
})

export const initTime = (time: Date): InitTimeAction => ({
  type: 'ws.init-time',
  payload: time
})

export interface InitTimeAction extends Action {
  type: 'ws.init-time'
  payload: Date
}
export type WsAction =
  | WsConnectAction
  | WsConnectSuccessAction
  | WsConnectFailureAction
  | AdjustServerTimeDelayAction
  | AdjustServerTimeDelaySuccessAction
  | LogErrorAction
  | ForceReloadAction
  | LogServerMetadataAction
  | InitTimeAction
