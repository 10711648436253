import React, { FC, useEffect, useState } from 'react'
import { fetchAlertFilters } from '../../store/alertFilter/actions'
import { getAlertFilters } from '../../store/alertFilter/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsOpenFin } from '../../store/webSettings/selectors'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import FinHeaderButtons from '../ComponentHeader/helpers'
import Modal from '../GenericModal/Modal'
import AlertFilterEdit from './AlertFilterEdit'
import AlertFilterList from './AlertFilterList'

export interface Props {
  closeFunc: () => void
}
const AlertFilterEditor: FC<Props> = ({ closeFunc }) => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchAlertFilters())
  }, [])
  const alertFilters = useAppSelector(getAlertFilters)
  const selectedId = alertFilters.length > 0 ? alertFilters[0].id : 0
  const [selectedAlertFilterId, setSelectedAlertFilterId] = useState(selectedId)

  const contentModal = (
    <>
      <ComponentHeader
        title="Alert Manager"
        headerButtons={<FinHeaderButtons title="AlertManager" />}
      />
      <AlertFilterList
        filters={alertFilters}
        selectAlertFilterId={setSelectedAlertFilterId}
        selectedAlertFilterId={selectedAlertFilterId}
      />
      <AlertFilterEdit
        filterId={selectedAlertFilterId}
        closeFunc={closeFunc}
        key={selectedAlertFilterId}
      />
    </>
  )

  return <>{isOpenFin ? contentModal : <Modal>{contentModal}</Modal>}</>
}

export default AlertFilterEditor
