import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../Grid/grid.module.scss'
import { queueStatsFetch } from '../../../store/admin/queueStats/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import QueueStatsGrid from './Grid/QueueStatsGrid'
import FinHeaderButtons from '../../ComponentHeader/helpers'

const QueueStats: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(queueStatsFetch())
  }, [])
  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader
        title="Queue Stats"
        headerButtons={<FinHeaderButtons title="QueueStats" />}
      />
      <QueueStatsGrid />
    </div>
  )
}
export default QueueStats
