import cx from 'classnames'
import React, { FC, useEffect } from 'react'

import {
  activityTickerFetch,
  unsubscribeFromTicker
} from '../../../store/admin/activityTicker/actions'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { getCurrentTheme } from '../../../store/userPreferences/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import gridStyles from '../../Grid/grid.module.scss'
import ActivityTickerGrid from './Grid/ActivityTickerGrid'
import FinHeaderButtons from '../../ComponentHeader/helpers'

const ActivityTicker: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  useEffect(() => {
    dispatch(activityTickerFetch())
    return () => {
      dispatch(unsubscribeFromTicker())
    }
  }, [])

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Activity Ticker"
        headerButtons={<FinHeaderButtons title="ActivityTicker" />}
      />
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <ActivityTickerGrid />
      </div>
    </div>
  )
}

export default ActivityTicker
