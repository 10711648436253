import React, { ComponentType, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce/lib'
import styles from '../assets/scss/global.module.scss'
import ForceReloadMessage from '../components/ForceReloadMessage/ForceReloadMessage'
import Message from '../components/Message/Message'
import {
  actionsToListenToForLocalStorage,
  stateValueForLocalStorage
} from '../config'
import { getAuthToken } from '../store/auth/selectors'
import { fetchDepth } from '../store/depthOfMarket/actions'
import { fetchResubmitOrders, fetchUserOrders } from '../store/order/actions'
import { handleWindowSettings } from '../store/settings/actions'
import { useAppSelector } from '../store/types'
import { getIsOpenFin } from '../store/webSettings/selectors'
import {
  adjustServerTimeDelay,
  connect,
  logServerMetadata
} from '../store/ws/actions'
import {
  getMustReload,
  hasError,
  isConnected,
  isPending
} from '../store/ws/selectors'

export const needsWSConnection =
  <Props extends object>(Component: ComponentType<Props>) =>
  (props: Props) => {
    const authToken = useSelector(getAuthToken)
    const connected = useSelector(isConnected)
    const pending = useSelector(isPending)
    const error = useSelector(hasError)
    const mustReload = useSelector(getMustReload)
    const dispatch = useDispatch()
    const [initialized, setInitialized] = useState(connected)

    useEffect(() => {
      if (authToken && !pending && !connected && !error) {
        dispatch(connect())
      }
    }, [authToken, pending, connected, error])

    useEffect(() => {
      if (connected && !initialized) {
        setInitialized(true)
        dispatch(
          handleWindowSettings(
            actionsToListenToForLocalStorage,
            stateValueForLocalStorage
          )
        )
        dispatch(logServerMetadata())
        dispatch(adjustServerTimeDelay())
        dispatch(fetchUserOrders())
        dispatch(fetchResubmitOrders())
        dispatch(fetchDepth())
      }
    }, [connected, initialized])

    const [state] = useDebounce(
      pending ? 'pending' : error ? 'error' : 'ready',
      1000,
      { leading: true }
    )

    const isOpenFin = useAppSelector(getIsOpenFin)

    return (
      <div
        className={isOpenFin ? styles.finColumnWrapper : styles.columnWrapper}
      >
        {mustReload ? (
          <ForceReloadMessage />
        ) : state === 'pending' || !connected ? (
          <Message key="pending">
            Establishing connection to the server…
          </Message>
        ) : state === 'error' ? (
          <Message key="error" type="error">
            The connection to the server was lost. Retrying in a few seconds…
          </Message>
        ) : null}
        <Component {...props} />
      </div>
    )
  }
