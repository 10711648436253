import React, { ComponentType, useCallback } from 'react'
import { useClipboard } from './hooks/useClipboard.tsx'

type ClipboardComponent = {
  copyToClipboard: (clipboardText: string) => void
}

export const WithClipboard = <Props extends ClipboardComponent>(
  Component: ComponentType<Props>
) => {
  return (props: Omit<Props, 'copyToClipboard'>) => {
    const writeClipboardText = useClipboard()
    const copyToClipboard = useCallback((text: string) => {
      writeClipboardText(text)
    }, [])
    // @ts-ignore
    return <Component copyToClipboard={copyToClipboard} {...props} />
  }
}
