import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  RowGroupOpenedEvent,
  SizeColumnsToContentStrategy
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { faArrowsToDottedLine } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  collapseAllRows,
  handleRowExpandedChange
} from '../../../../helpers/gridFunctions'
import {
  adminTradeBlotterFetch,
  unsubscribeFromBlotter
} from '../../../../store/admin/adminTradeBlotter/actions'
import {
  aggressAttemptFetch,
  unsubscribeFromAggressAttempt
} from '../../../../store/admin/aggressAttempt/actions'
import {
  getAggressAttemptError,
  getAggressAttempts
} from '../../../../store/admin/aggressAttempt/selectors'
import { AggressAttempt } from '../../../../store/admin/aggressAttempt/types'
import { selectSecurity } from '../../../../store/depthOfMarket/actions'
import { getQuoteReliability } from '../../../../store/securities/selectors'
import { updateColumnsOrder } from '../../../../store/settings/actions'
import { getAggressAttemptsColumnOrder } from '../../../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import Checkbox from '../../../Checkbox'
import ComponentHeader from '../../../ComponentHeader/ComponentHeader'
import gridStyles from '../../../Grid/grid.module.scss'
import TradeBlotterModal from '../../AdminTradeBlotter/AdminTradeBlotterModal'
import { useAdminStickyColumns } from '../../helpers'
import AggressDetailRenderer from '../AggressDetailRender'
import { autoSizedColumnIds, getCustomizableColumns } from './columnDefs'
import FinHeaderButtons from '../../../ComponentHeader/helpers'

export interface Props {
  setSelectedTab?: (st: string) => void
}

const getAggressAttemptsRowId: GetRowIdFunc<AggressAttempt> = ({ data }) =>
  `${data.takerOrderId}`
const AggressAttemptsGrid: FC<Props> = ({ setSelectedTab }) => {
  const gridRef = useRef<AgGridReact<AggressAttempt>>(null)
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)

  const aggressAttempts = useAppSelector(getAggressAttempts)
  const aggressAttemptsError = useAppSelector(getAggressAttemptError)
  const columnsOrder = useAppSelector(getAggressAttemptsColumnOrder) ?? []
  const quoteReliability = useAppSelector(getQuoteReliability)(0)

  const [traded, setTraded] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(true)
  const [showCollapseRows, setShowCollapseRows] = useState(false)
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [displayModal, toggleModal] = useState(false)

  useEffect(() => {
    dispatch(aggressAttemptFetch())
    dispatch(adminTradeBlotterFetch())
    return () => {
      dispatch(unsubscribeFromAggressAttempt())
      dispatch(unsubscribeFromBlotter())
    }
  }, [])

  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  const columns: ColDef[] = useMemo(() => {
    return getCustomizableColumns(displayModal, toggleModal, setSelectedRow)
  }, [])

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })
      const sortModel = [{ colId: 'takerOrderId', sort: 'desc' } as const]
      api.applyColumnState({ state: sortModel })
    }
  }, [])

  const onColumnsChanged = useCallback(
    (displayed: string[]) => {
      dispatch(updateColumnsOrder('aggressAttemptSettings', displayed))
    },
    [dispatch]
  )

  const { handleColumnChange, columnDefs } = useAdminStickyColumns(
    columnsOrder,
    columns,
    onColumnsChanged
  )

  const rowStyle = useCallback((params: any) => {
    const status = params.data.status
    const getBgColor = () => {
      if (
        status === 'Pending' ||
        status.includes('Timed Out') ||
        status.includes('Expired')
      ) {
        return 'var(--darkOrange)'
      } else if (status.includes('Rejected')) return 'var(--midRed)'
      else if (
        status.includes('price off market') ||
        status.includes('passive=')
      ) {
        return 'var(--midBlue)'
      } else if (status.includes('Traded')) return 'initial'
      else return 'var(--lightRed)'
    }
    return {
      color: `var(--${status === 'Traded' ? 'primaryTextColor' : 'whiteText'})`,
      backgroundColor: getBgColor()
    }
  }, [])

  const rowData = useMemo<typeof aggressAttempts>(() => {
    if (!aggressAttempts) {
      return []
    }
    if (traded && failed) {
      return aggressAttempts
    }
    if (traded) {
      return aggressAttempts.filter((d) => d.status === 'Traded')
    }
    if (failed) {
      return aggressAttempts.filter((d) => d.status !== 'Traded')
    }
    return []
  }, [traded, failed, aggressAttempts])

  const onRowExpandedChange = useCallback(
    (e: RowGroupOpenedEvent) =>
      handleRowExpandedChange<AggressAttempt>(e, setShowCollapseRows),
    []
  )

  if (aggressAttemptsError) {
    return <div>{aggressAttemptsError}</div>
  }

  const onDoubleClick = (row: any) => {
    dispatch(selectSecurity(0, row.data.securityId, quoteReliability))
    setSelectedTab!('Watchlist')
  }

  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
    colIds: autoSizedColumnIds
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Aggress Attempts"
        headerButtons={<FinHeaderButtons title="AggressAttempts" />}
      />
      <div className={gridStyles.optionsContainer}>
        <Checkbox
          locator={`aggressAttempt-traded`}
          checked={traded}
          onChange={() => {
            setTraded(!traded)
          }}
        >
          Traded
        </Checkbox>
        <Checkbox
          locator={`aggressAttempt-failed`}
          checked={failed}
          onChange={() => {
            setFailed(!failed)
          }}
        >
          Failed
        </Checkbox>
        {showCollapseRows && (
          <button
            onClick={() => collapseAllRows<AggressAttempt>(gridRef)}
            title="Collapse Rows"
          >
            <FontAwesomeIcon icon={faArrowsToDottedLine} />
          </button>
        )}
      </div>
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <AgGridReact<AggressAttempt>
          ref={gridRef}
          onGridReady={onGridReady}
          getRowId={getAggressAttemptsRowId}
          rowData={rowData}
          //  selection
          onRowGroupOpened={onRowExpandedChange}
          onRowDoubleClicked={onDoubleClick}
          //  columns
          columnMenu="legacy"
          defaultColDef={defaultColumnDefinitions}
          columnDefs={columnDefs}
          onDragStopped={handleColumnChange}
          onColumnVisible={handleColumnChange}
          suppressColumnVirtualisation={true}
          autoSizeStrategy={autoSizeStrategy}
          // master/detail
          embedFullWidthRows={true}
          masterDetail={true}
          detailCellRenderer={AggressDetailRenderer}
          detailRowHeight={170}
          //  stopping things
          suppressDragLeaveHidesColumns={true}
          suppressRowTransform={true}
          suppressScrollOnNewData={true}
          //  visual
          overlayLoadingTemplate="Loading Aggress Attempts…"
          overlayNoRowsTemplate="No aggress attempts found."
          getRowStyle={rowStyle}
        />
        {displayModal && (
          <TradeBlotterModal
            closeFunc={() => toggleModal(false)}
            paramId={selectedRow}
          />
        )}
      </div>
    </div>
  )
}

export default AggressAttemptsGrid
