import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import GenericBondsSearch from '../../../components/GenericBondsSearch/GenericBondsSearch'
import { useFetchBondsSearchResults } from '../../../helpers/hooks/useFetchBondsSearchResults'
import {
  setDomSecurityByQuery,
  updatePopouts
} from '../../../store/depthOfMarket/actions'
import { getIssuerFilter } from '../../../store/securities/selectors'

import styles from '../../../components/SearchBondsInput/SearchBondsResultsDropdown.module.scss'
import useBroadcastChannel from '../../../helpers/hooks/useBroadcastChannel'
import { useOpenFin } from '../../../helpers/hooks/useOpenFin'
import { useAppDispatch, useAppSelector } from '../../../store/types'

interface DepthOfMarketTitleBondsSearchProps {
  onSecurityClicked?: (previousId: number, securityId: number) => void
  gridIndex: number
  previousId: number
}

const DepthOfMarketTitleBondsSearch = ({
  gridIndex,
  onSecurityClicked,
  previousId
}: DepthOfMarketTitleBondsSearchProps) => {
  // we separate this out into two variables so calls to set the search term that are just about formatting
  // don't rerun the search
  const [displayedSearch, setDisplayedSearch] = useState('')
  const [searchString, setSearchString] = useState('')
  const { isOpenFin, finWindowAction } = useOpenFin()
  const sendMessage = useBroadcastChannel('broadcastchannel')

  const dispatch = useAppDispatch()

  const {
    watchlist,
    watchListResults,
    notInWatchListResults,
    showLiveChecked
  } = useFetchBondsSearchResults(gridIndex, searchString)

  // when user clicks "show all" in the bonds list associated with the same grid index, it sets an issuer filter
  // get that filter so we can format the search results
  const issuerFilter = useAppSelector(getIssuerFilter)(gridIndex)

  useEffect(() => {
    // we already hit clear and redux hasn't caught up
    if (!displayedSearch) return
    if (issuerFilter) {
      setDisplayedSearch(`${issuerFilter} `)
    }
  }, [watchlist, issuerFilter])

  const onClear = useCallback(() => {
    setSearchString('')
    setDisplayedSearch('')
  }, [])

  const handleSearchChanged = useCallback((search: string) => {
    setDisplayedSearch('')
    setSearchString(search)
  }, [])

  const handleSecurityClicked = useCallback(
    (securityId: number) => {
      dispatch(updatePopouts(previousId, securityId))
      onSecurityClicked && onSecurityClicked(previousId, securityId)

      // OpenFin specific
      sendMessage({ action: updatePopouts(previousId, securityId) })
      finWindowAction(`Depth/${previousId}`, 'close')
    },
    [previousId, dispatch, sendMessage]
  )

  const onSearchCommit = useCallback(
    (search: string | undefined) => {
      if (search) {
        dispatch(setDomSecurityByQuery(previousId, search))
      }
    },
    [dispatch, gridIndex]
  )

  return (
    <GenericBondsSearch
      search={searchString}
      formattedSearch={displayedSearch}
      onSearchChanged={handleSearchChanged}
      watchListResults={watchListResults}
      notInWatchListResults={notInWatchListResults}
      showLiveChecked={showLiveChecked}
      onClear={onClear}
      onSearchCommit={onSearchCommit}
      onSecurityClicked={handleSecurityClicked}
      menuStyle={cx(styles.contentDropDownMenu, isOpenFin && styles.finMenu)}
    />
  )
}

export default DepthOfMarketTitleBondsSearch
