import dayjs from 'dayjs'
import React, { FC, useState } from 'react'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import gridStyles from '../../Grid/grid.module.scss'
import ManagementReportWrapper from './ManagementReportWrapper'
import styles from './ManagementReports.module.scss'
import FinHeaderButtons from '../../ComponentHeader/helpers'

const ManagementReportMainPage: FC = () => {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Management Reports"
        headerButtons={<FinHeaderButtons title="ManagementReports" />}
      />
      <div className={styles.reportsContainer}>
        <input
          className={styles.datePicker}
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        <ManagementReportWrapper date={dayjs(date).toDate()} />
      </div>
    </div>
  )
}

export default ManagementReportMainPage
