import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import '@openfonts/lato_latin'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-061312}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bonds.com,_LLC.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{BondsPro}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{BondsPro}_need_to_be_licensed___{BondsPro}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_August_2025}____[v3]_[01]_MTc1NDY5NDAwMDAwMA==ebb41cc2776b0b91d47b0f8a1ebe2fbd'
)

import config from './config'
import { initHub } from './helpers/hub'
import OidcWrapper from './routing/OidcWrapper'
import OktaWrapper from './routing/OktaWrapper'
import getStore from './store'

// AG Grid css
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'

// Contexify css
import 'react-contexify/ReactContexify.css'

// AG Grid modules
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ClipboardModule,
  SetFilterModule
])

const store = getStore()
if (import.meta.env.MODE === 'development') {
  ;(window as any).__REDUX_STORE__ = store
}
initHub(store)

const replay =
  config.sentry.replay &&
  Sentry.replayIntegration({
    ...config.sentry.replay,
    workerUrl: '/worker.min.js'
  })

const integrations: any[] = [
  Sentry.browserTracingIntegration(),
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect: React.useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
  })
]

if (replay) {
  integrations.push(replay)
}

Sentry.init({
  dsn: 'https://5d88b9bcf3b767b9f0e88827c59e379d@o4506503129989120.ingest.sentry.io/4506507821580288',
  integrations,
  normalizeDepth: 5,
  ...config.sentry.baseProps
})

const oidcConfig = {
  authority: config.keycloak.domain,
  client_id: config.keycloak.client,
  domain: config.keycloak.domain,
  redirect_uri: config.keycloak.redirectUri,
  post_logout_redirect_uri: config.keycloak.redirectUri,
  silentRequestTimeoutInSeconds: config.api.refreshTokenTimeout,
  stopCheckSessionOnError: true
}

const useKeycloak = config.keycloak.useKeycloak

const container = document.getElementById('app')
container &&
  createRoot(container).render(
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => {
        const err = error instanceof Error ? error.toString() : 'Unknown error'
        return (
          <>
            <h2>An error occurred:</h2>
            <pre className="error-boundary">
              {err}
              {componentStack}
            </pre>
          </>
        )
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          {useKeycloak ? <OidcWrapper {...oidcConfig} /> : <OktaWrapper />}
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  )
