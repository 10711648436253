import { setUser } from '@sentry/react'
import React, { useCallback, useContext } from 'react'
import { noop } from 'rxjs'
import { useOpenFin } from '../helpers/hooks/useOpenFin'

/*
  While we could pass the signout function into App and then prop dril that into the menu and
  All Markets closed, I wasn't sure that would work well for Openfin, so I'm doing it like this
 */
type SessionContextType = {
  signout: () => void
  editProfile?: () => void
}

export const SessionContext = React.createContext<SessionContextType>({
  signout: noop,
  editProfile: noop
})

export const useSessionSignout = () => {
  const { signout } = useContext(SessionContext)
  return signout
}

export const useUserSession = () => {
  const { signout, editProfile } = useContext(SessionContext)
  const { toggleFrame, finWindowAction, deregisterNotifications } = useOpenFin()

  const handleSignout = useCallback(() => {
    signout()
    setUser(null)

    // OpenFin specific
    toggleFrame(true)
    finWindowAction(null, 'hide', true)
    deregisterNotifications()
  }, [signout, toggleFrame, finWindowAction, deregisterNotifications])

  return {
    handleSignout,
    editProfile
  }
}
