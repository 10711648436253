import cx from 'classnames'
import React, { FC, useState } from 'react'
import gridStyles from '../../Grid/grid.module.scss'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import EditableTiers from './EditableTiers'
import LiveTiers from './LiveTiers'
import styles from './Tiers.module.scss'
import FinHeaderButtons from '../../ComponentHeader/helpers'

const Tiers: FC = () => {
  const [editableSelected, setEditableSelected] = useState<boolean>(true)

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Tiers"
        headerButtons={<FinHeaderButtons title="Tiers" />}
      />
      <div className={gridStyles.optionsContainer}>
        <span
          className={cx(
            editableSelected && styles.tabSelected,
            styles.tiersTab
          )}
          onClick={() => setEditableSelected(true)}
        >
          Editable Tiers
        </span>
        <span
          className={cx(
            !editableSelected && styles.tabSelected,
            styles.tiersTab
          )}
          onClick={() => setEditableSelected(false)}
        >
          Live Tiers
        </span>
      </div>
      {editableSelected && <EditableTiers />}
      {!editableSelected && <LiveTiers />}
    </div>
  )
}

export default Tiers
