import { GetContextMenuItemsParams, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useRef, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  getAdminTradeBlotter,
  getAdminTradeBlotterError
} from '../../../../store/admin/adminTradeBlotter/selectors'
import { TradeInfo } from '../../../../store/admin/adminTradeBlotter/types'
import { useAppSelector } from '../../../../store/types'
import { columnDefinitions } from './columnDefs'
import { useClipboard } from '../../../../helpers/hooks/useClipboard'

interface Props {
  onDoubleClick: (row: any) => void
}
type GridType = TradeInfo
const TradeBlotterGrid: FC<Props> = (props: Props) => {
  const writeClipboardText = useClipboard()
  const onDoubleClick = props.onDoubleClick
  const tradeBlotter = useAppSelector(getAdminTradeBlotter)
  const tradeBlotterError = useAppSelector(getAdminTradeBlotterError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  // useRef
  const blotterRef = useRef<any[] | undefined>(undefined)
  blotterRef.current = tradeBlotter

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })

      const columnState = localStorage.getItem('columnState')
      if (columnState) {
        const parsedState = JSON.parse(columnState)
        api.applyColumnState(parsedState)
      }
    }
  }, [])

  const copyTradeDetails = (data: any) => {
    const trades = blotterRef.current?.filter(
      (items) => items.tradeId === data.tradeId
    )
    const text =
      trades && trades.length > 1
        ? `${data.ticketId}
        ${trades[0].tradeId}: ${trades[0].customer} (${trades[0].operator}) ${trades[0].buySell} ${trades[0].amount} ${trades[0].security} (${trades[0].isin}) @ ${trades[0].priceTrade} (${trades[0].priceMarkUpDown})
        ${trades[1].tradeId}: ${trades[1].customer} (${trades[1].operator}) ${trades[1].buySell} ${trades[1].amount} ${trades[1].security} (${trades[1].isin}) @ ${trades[1].priceTrade} (${trades[1].priceMarkUpDown})`
        : ''
    writeClipboardText(text)
  }
  const copyTradeLeg = (data: any) => {
    const text = `${data.ticketId}: ${data.customer} (${data.operator}) ${data.buySell} ${data.amount} ${data.security} (${data.isin}) @ ${data.priceTrade} (${data.priceMarkUpDown})`
    writeClipboardText(text)
  }

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<GridType>) => {
      const data = params.node?.data
      return [
        {
          name: 'Copy Trade Details (Both Sides)',
          action() {
            copyTradeDetails(data)
          }
        },
        {
          name: 'Copy Trade Leg',
          action() {
            copyTradeLeg(data)
          }
        }
      ]
    },
    []
  )

  const onDragStoppedOrColumnVisible = useCallback(
    ({ api }: { api: GridApi }) => {
      const columnState = JSON.stringify(api.getColumnState())
      localStorage.setItem('columnState', columnState)
    },
    []
  )

  if (tradeBlotterError) {
    return <div>{tradeBlotterError}</div>
  }

  return (
    <AgGridReact
      getContextMenuItems={getContextMenuItems}
      rowData={tradeBlotter}
      columnDefs={columnDefinitions}
      columnMenu="legacy"
      overlayNoRowsTemplate="No trades found."
      overlayLoadingTemplate="Loading Trade Blotter…"
      defaultColDef={defaultColumnDefinitions}
      onGridReady={onGridReady}
      onRowDoubleClicked={onDoubleClick}
      onDragStopped={onDragStoppedOrColumnVisible}
      onColumnVisible={onDragStoppedOrColumnVisible}
      maintainColumnOrder={true}
    />
  )
}

export default TradeBlotterGrid
