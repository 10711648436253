import React, { FC, useCallback } from 'react'
import { abbreviateVolume } from '../../helpers/formatting'
import useBroadcastChannel from '../../helpers/hooks/useBroadcastChannel'
import { setBenchmarkSearch } from '../../store/benchmarkData/actions'
import { BenchmarkInfo, BenchmarkList } from '../../store/benchmarkData/types'
import { setQueryFilter } from '../../store/securities/actions'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsOpenFin } from '../../store/webSettings/selectors'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import FinHeaderButtons from '../ComponentHeader/helpers'
import styles from './benchmarkData.module.scss'

interface Props {
  benchmarkData: BenchmarkInfo
}

const BenchmarkData: FC<Props> = ({ benchmarkData }) => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const dispatch = useAppDispatch()
  const sendMessage = useBroadcastChannel('benchmarkchannel')

  const searchSecurity = useCallback(
    (isin: string) => {
      dispatch(setBenchmarkSearch(isin))
      dispatch(setQueryFilter(0, isin))
      sendMessage({ action: setQueryFilter(0, isin), data: isin })
    },
    [dispatch, sendMessage]
  )

  return (
    <div className={isOpenFin ? styles.finSizing : styles.webSizing}>
      {isOpenFin && (
        <ComponentHeader
          title="Benchmarks"
          headerButtons={<FinHeaderButtons title="Benchmarks" />}
        />
      )}
      <div className={styles.benchmarkContainer}>
        <div className={styles.benchmarkTitle}>
          Live on <br /> BondsPro:
        </div>
        <div className={styles.benchmarkData}>
          {benchmarkData.totalOrders.toLocaleString()}{' '}
          <div className={styles.benchmarkLabel}>TOTAL ORDERS</div>
        </div>
        <div className={styles.benchmarkData}>
          {abbreviateVolume(benchmarkData.notional * 1000)}{' '}
          <div className={styles.benchmarkLabel}>NOTIONAL</div>
        </div>
        <div className={styles.benchmarkTitle}>
          Treasury <br /> Benchmarks:
        </div>
        {benchmarkData.benchmarkData.map((item: BenchmarkList) => (
          <div
            key={item.id}
            className={styles.table}
            onClick={() => searchSecurity(item.isin)}
          >
            <div className={styles.benchmarkYearLabel}>
              {item.benchmarkName}
            </div>
            <div>
              {item.bestBid}/{item.bestOffer}
            </div>
            <div>{item.yield ? item.yield.toFixed(2) : 'N/A'}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BenchmarkData
