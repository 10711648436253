import dayjs from 'dayjs'
import React, { FC, useCallback, useEffect } from 'react'
import { useOpenFin } from '../../helpers/hooks/useOpenFin'
import { domLimitExceeded } from '../../store/depthOfMarket/actions'
import {
  getDepthSecurities,
  getDOMLimitExceeded,
  getMaxDOMs,
  getPopoutSecurityIds
} from '../../store/depthOfMarket/selectors'
import { fetchSecuritiesByIds } from '../../store/securities/actions'
import { SecurityStaticData } from '../../store/securities/reducer'
import { addToast } from '../../store/toasts/actions'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsAdmin } from '../../store/webSettings/selectors'
import styles from '../DepthOfMarket/depthPopout.module.scss'
import PopoutDepthOfMarket from '../DepthOfMarket/PopoutDepthOfMarket'

const getKey = (
  security: SecurityStaticData,
  index: number,
  all: SecurityStaticData[]
) => {
  const prevSecurities = all.slice(0, index)
  const matchingSecurities = prevSecurities.filter((s) => s.id === security.id)
  return matchingSecurities.length
}
const DepthOpener: FC = () => {
  const dispatch = useAppDispatch()
  const { openPlatformWindow, isOpenFin } = useOpenFin()
  const isAdmin = useAppSelector(getIsAdmin)
  const securityIds = useAppSelector(getPopoutSecurityIds)
  const securities = useAppSelector(getDepthSecurities)
  const domLimit = useAppSelector(getDOMLimitExceeded)
  const maxDOMs = useAppSelector(getMaxDOMs)

  useEffect(() => {
    // todo: check to see if we already have static/order data
    if (securityIds.length) {
      dispatch(fetchSecuritiesByIds(securityIds))
    }
  }, [securityIds, dispatch])

  useEffect(() => {
    domLimit &&
      dispatch(
        addToast({
          message: `Maximum amount of depth windows (${maxDOMs}) have been opened. Please close
      some windows before opening more.`,
          options: {
            toastId: `domlimit-${dayjs().toString()}`,
            type: 'warning',
            onClose: () => {
              dispatch(domLimitExceeded(false))
            }
          }
        })
      )
  }, [domLimit, dispatch, maxDOMs])

  const getDepthPopout = useCallback((name: string, index: number) => {
    openPlatformWindow(name)
    return <div key={index} />
  }, [])

  if (!securities.length) return <></>

  return (
    <div className={isOpenFin ? '' : styles.domContainer}>
      {securities.map((security, i, all) =>
        // TS doesn't believe we have this even though we filter in the selector
        security?.id ? (
          isOpenFin ? (
            getDepthPopout(`Depth/${security.id}`, i)
          ) : (
            <PopoutDepthOfMarket
              security={security}
              showCloseAll={all.length > 1}
              gridIndex={0}
              isAdmin={isAdmin}
              key={`${security.id}-${getKey(
                security,
                i,
                all as unknown as SecurityStaticData[]
              )}`}
            />
          )
        ) : (
          <></>
        )
      )}
    </div>
  )
}

DepthOpener.displayName = 'DepthOpener'

export default DepthOpener
