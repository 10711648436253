import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import useResizeObserver from 'use-resize-observer'
import ModalFooter from '../../components/Modal/ModalFooter/ModalFooter'
import { useOpenFin } from '../../helpers/hooks/useOpenFin'
import { fetchBooks } from '../../store/books/actions'
import { fetchSecuritiesByIds } from '../../store/securities/actions'
import { getSecurityStaticDataById } from '../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsAdmin } from '../../store/webSettings/selectors'
import PopoutDepthOfMarket from '../DepthOfMarket/PopoutDepthOfMarket'

const OpenFinDepth = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { changeDimensions } = useOpenFin()
  const isAdmin = useAppSelector(getIsAdmin)

  useEffect(() => {
    dispatch(fetchSecuritiesByIds([Number(id)]))
    dispatch(fetchBooks())
  }, [dispatch, id])
  const security = useAppSelector(getSecurityStaticDataById)(Number(id))

  const lastHeightRef = useRef<number | undefined>()
  const { ref, height } = useResizeObserver<HTMLDivElement>()

  useEffect(() => {
    if (height && height !== lastHeightRef.current) {
      changeDimensions(`Depth/${id}`, 543, height)
      lastHeightRef.current = height
    }
  }, [height, id, changeDimensions])

  if (!security) return
  return (
    <div ref={ref}>
      <PopoutDepthOfMarket
        security={security}
        showCloseAll={false}
        gridIndex={0}
        isAdmin={isAdmin}
        key={id}
      />
      <ModalFooter dataTestId={`Depth/${id}`} />
    </div>
  )
}

export default OpenFinDepth
