import dayjs from 'dayjs'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  filter,
  map,
  mergeMap,
  takeUntil
} from 'rxjs/operators'
import { createOpenFinAlert } from '../../containers/Openfin/helpers'
import { getHub } from '../../helpers/hub'
import {
  createActivityFromCancelOrderAlert,
  createActivityFromCounteredToppedAlert,
  createActivityFromNewOrderAlert,
  createActivityFromOrder,
  createActivityFromTradingNowAlert
} from '../activity/helpers'
import { Activity } from '../activity/types'
import { AddOrUpdateUserOrdersAction } from '../order/actions'
import { fetchSecuritiesByIds } from '../securities/actions'
import { getSecurityStaticDataById } from '../securities/selectors'
import { getIsOpenFin } from '../webSettings/selectors'
import { logError } from '../ws/actions'
import { getInitTime } from '../ws/selectors'
import {
  addCancelOrderAlerts,
  AddCancelOrderAlerts,
  AddCounteredToppedAlerts,
  addCounteredToppedAlerts,
  addNewOrderAlerts,
  AddNewOrderAlerts,
  addTradingNowAlerts,
  AddTradingNowAlerts,
  SubscribeToAlertsAction,
  UnsubscribeFromAlertsAction
} from './actions'
import {
  cancelOrderAlertFromResponse,
  counteredToppedAlertFromResponse,
  newOrderAlertFromResponse,
  tradingNowAlertFromResponse
} from './helpers'
import {
  getMutedCounteredAlertsSecurityIds,
  getMutedNewOrderAlertsSecurityIds,
  getMutedToppedAlertsSecurityIds,
  getMutedTradingNowAlertsSecurityIds
} from './selectors'

const BUFFER_DELAY = 300

const notEmpty = (array: any[]) => array.length > 0

const subscribeToTradingNowAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetTradingNowAlerts')
        .pipe(
          map(tradingNowAlertFromResponse),
          filter((alert) => {
            const securityIds = getMutedTradingNowAlertsSecurityIds(
              state$.value
            )
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addTradingNowAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToNewOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetNewOrderAlerts')
        .pipe(
          map(newOrderAlertFromResponse),
          filter((alert) => {
            const securityIds = getMutedNewOrderAlertsSecurityIds(state$.value)
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addNewOrderAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToOrderCancelAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetOrderCancelAlerts')
        .pipe(
          map(cancelOrderAlertFromResponse),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addCancelOrderAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToCounteredToppedAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetCounteredToppedAlerts')
        .pipe(
          map(counteredToppedAlertFromResponse),
          filter((alert) => {
            const securityIds =
              alert.alertType === 'countered'
                ? getMutedCounteredAlertsSecurityIds(state$.value)
                : getMutedToppedAlertsSecurityIds(state$.value)
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addCounteredToppedAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const fetchSecurityInfoForAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddTradingNowAlerts>('alerts.addTradingNowAlerts'),
    mergeMap((action) => {
      const { tradingNowAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = tradingNowAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )

const fetchSecurityInfoForNewOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddNewOrderAlerts>('alerts.addNewOrderAlerts'),
    mergeMap((action) => {
      const { newOrderAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = newOrderAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )

const fetchSecurityInfoForCancelOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddCancelOrderAlerts>('alerts.addCancelOrderAlerts'),
    mergeMap((action) => {
      const { cancelOrderAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = cancelOrderAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )
const processedIds = new Set<string>()
const openFinOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddOrUpdateUserOrdersAction>('order.addOrUpdateUserOrders'),
    filter(() => getIsOpenFin(state$.value)),
    mergeMap((action) => {
      const activities: Activity[] = []
      action.payload.forEach((order) => {
        const security = getSecurityStaticDataById(state$.value)(
          order.securityId
        )

        if (
          [
            'accepted',
            'rejected',
            'cancelled',
            'waitingForConfirmation'
          ].includes(order.status) &&
          dayjs(order.submitTime).isAfter(getInitTime(state$.value)) &&
          !processedIds.has(order.id) &&
          security
        ) {
          const activity = createActivityFromOrder(order, security)
          if (activity && !activity.hasAggressorActions) {
            activities.push(activity)
            processedIds.add(order.id)
          }
        }
      })

      createOpenFinAlert(activities)
      return EMPTY
    })
  )

const openFinTradeAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<
      | AddTradingNowAlerts
      | AddNewOrderAlerts
      | AddCounteredToppedAlerts
      | AddCancelOrderAlerts
    >(
      'alerts.addTradingNowAlerts',
      'alerts.addNewOrderAlerts',
      'alerts.addCounteredToppedAlerts',
      'alerts.addCancelOrderAlerts'
    ),
    filter(() => getIsOpenFin(state$.value)),
    mergeMap((action) => {
      const activity: Activity[] = []
      const initTime = getInitTime(state$.value)
      switch (action.type) {
        case 'alerts.addTradingNowAlerts':
          const { tradingNowAlerts } = action.payload
          tradingNowAlerts.forEach(
            (alert) =>
              dayjs(alert.dateTime).isAfter(initTime) &&
              activity.push(createActivityFromTradingNowAlert(alert))
          )
          break
        case 'alerts.addNewOrderAlerts':
          const { newOrderAlerts } = action.payload
          newOrderAlerts.forEach(
            (alert) =>
              dayjs(alert.dateTime).isAfter(initTime) &&
              activity.push(createActivityFromNewOrderAlert(alert))
          )
          break
        case 'alerts.addCounteredToppedAlerts':
          const { counteredToppedAlerts } = action.payload
          counteredToppedAlerts.forEach(
            (alert) =>
              dayjs(alert.dateTime).isAfter(initTime) &&
              activity.push(createActivityFromCounteredToppedAlert(alert))
          )
          break
        case 'alerts.addCancelOrderAlerts':
          const { cancelOrderAlerts } = action.payload
          cancelOrderAlerts.forEach(
            (alert) =>
              dayjs(alert.dateTime).isAfter(initTime) &&
              activity.push(createActivityFromCancelOrderAlert(alert))
          )
          break
        default:
          break
      }
      createOpenFinAlert(activity)
      return EMPTY
    })
  )

export default combineEpics(
  subscribeToTradingNowAlertsEpic,
  subscribeToCounteredToppedAlertsEpic,
  fetchSecurityInfoForAlertsEpic,
  fetchSecurityInfoForNewOrderAlertsEpic,
  fetchSecurityInfoForCancelOrderAlertsEpic,
  subscribeToNewOrderAlertsEpic,
  subscribeToOrderCancelAlertsEpic,
  openFinOrderAlertsEpic,
  openFinTradeAlertsEpic
)
