import { OpenFin } from '@openfin/core'
import * as Notifications from '@openfin/workspace/notifications'
import { useCallback, useEffect } from 'react'
import { openfinConfig } from '../../containers/Openfin/helpers'
import {
  subscribeToAlerts,
  unsubscribeFromAlerts
} from '../../store/alerts/actions'
import { popoutSecurity } from '../../store/depthOfMarket/actions'
import { getPopoutSecurityIds } from '../../store/depthOfMarket/selectors'
import { confirmOrder, rejectOrder } from '../../store/lastLook/actions'
import {
  getMarketClosed,
  getMarketClosedTimer
} from '../../store/market/selectors'
import { setTradeConfirmId } from '../../store/order/actions'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsAdmin, getIsOpenFin } from '../../store/webSettings/selectors'

export interface WindowAction {
  close(): void
  hide(): void
  minimize(): void
  restore(): void
  show(): void
}

export const useOpenFin = () => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const marketClosed = useAppSelector(getMarketClosed)
  const marketClosedTimer = useAppSelector(getMarketClosedTimer)
  const isAdmin = useAppSelector(getIsAdmin)
  const dispatch = useAppDispatch()
  const popoutIds = useAppSelector(getPopoutSecurityIds)

  // ------------------------WINDOW INFO------------------------ //

  const getChildWindows = useCallback(() => {
    return fin.Application.getCurrentSync().getChildWindows()
  }, [])

  const getTargetWindow = useCallback(
    async (name: string) => {
      const childWindows = await getChildWindows()
      return childWindows.find((win) => win.identity.name === name)
    },
    [getChildWindows]
  )

  const changeDimensions = useCallback(
    async (
      currentWindow: string,
      incomingWidth: number,
      incomingHeight?: number
    ) => {
      const targetWindow = await getTargetWindow(currentWindow)
      if (targetWindow) {
        await targetWindow.updateOptions({ maxWidth: incomingWidth })
        const bounds = await targetWindow.getBounds()
        const options = await targetWindow.getOptions()
        targetWindow
          .resizeTo(
            options.maxWidth,
            incomingHeight ?? bounds.height,
            'top-left'
          )
          .catch(console.warn)
      }
    },
    [getTargetWindow]
  )

  // ------------------------WINDOW CONTROLS------------------------ //
  const setWindows = useCallback((windowList: string[]) => {
    if (windowList.length > 0) {
      localStorage.setItem('openfinOpenWindows', windowList.toString())
    } else {
      localStorage.removeItem('openfinOpenWindows')
    }
  }, [])

  const removeFromLocalStorage = useCallback(
    (name: string) => {
      const currentWindows = localStorage
        .getItem('openfinOpenWindows')
        ?.split(',')
      const updatedWindows =
        currentWindows && currentWindows.filter((win) => win !== name)
      if (updatedWindows) {
        setWindows(updatedWindows)
      }
    },
    [setWindows]
  )

  const setWindowsInLocalStorage = useCallback(async () => {
    const childWindows = await getChildWindows()
    const windowList: string[] = []
    childWindows.forEach((child) => windowList.push(child.identity.name))
    setWindows(windowList)
  }, [getChildWindows, setWindows])

  const finWindowAction = useCallback(
    async (
      windowName: string | null,
      action: keyof WindowAction,
      isSignout?: boolean
    ) => {
      if (isOpenFin) {
        try {
          const performAction = async (win: OpenFin.Window) => {
            try {
              if (action !== 'close') {
                if (
                  action === 'hide' &&
                  isSignout &&
                  win.identity.name === 'Bonds.com'
                ) {
                  // skip the hide action to keep the price panel window
                  // open to the log in page
                  return
                }
                await win[action]?.()
                await win.setAsForeground()
                await setWindowsInLocalStorage()
              } else {
                // remove window from local storage first, then close
                // todo: there's probably a better way to handle this,
                // but for now it works.
                removeFromLocalStorage(win.identity.name)
                await win.close()
              }
            } catch (err) {
              console.error(`Failed to ${action} on window`, err)
            }
          }

          if (windowName) {
            // Perform action on a single window
            const targetWindow = await getTargetWindow(windowName)
            if (targetWindow) {
              await performAction(targetWindow)
            }
          } else {
            // Perform action on all child windows
            const childWindows = await getChildWindows()
            await Promise.all(childWindows.map((child) => performAction(child)))
          }
        } catch (err) {
          console.error(`Failed to ${action} windows:`, err)
        }
      }
    },
    [
      getChildWindows,
      getTargetWindow,
      removeFromLocalStorage,
      setWindowsInLocalStorage,
      isOpenFin
    ]
  )

  const toggleFrame = useCallback(
    async (frame: boolean) => {
      if (isOpenFin) {
        try {
          const targetWindow = await getTargetWindow('Bonds.com')
          if (targetWindow) {
            await targetWindow.updateOptions({ frame })
          }
        } catch (err) {
          console.error('Unable to toggle frame', err)
        }
      }
    },
    [isOpenFin, getTargetWindow]
  )

  useEffect(() => {
    // on nightly log out, add frame back and hide child windows
    if (isOpenFin && !isAdmin && marketClosed) {
      toggleFrame(true)
      finWindowAction(null, 'hide', true)
    }
  }, [
    marketClosedTimer,
    isAdmin,
    isOpenFin,
    finWindowAction,
    marketClosed,
    toggleFrame
  ])

  // ------------------------CREATE WINDOWS------------------------ //

  const openPlatformWindow = useCallback(
    async (name: string) => {
      const baseName =
        name === 'WatchlistManager'
          ? undefined
          : Object.keys(openfinConfig).find((key) => name.includes(key))

      const {
        windowHeight = 500,
        windowWidth = 500,
        isAdminWindow = false
      } = openfinConfig[baseName ?? name] || {}

      try {
        const targetWindow = await getTargetWindow(name)
        if (!targetWindow) {
          const options = {
            name,
            url: `/${isAdminWindow ? 'admin/' : ''}${name}`,
            frame: false,
            defaultWidth: windowWidth,
            defaultHeight: windowHeight,
            saveWindowState: true
          }
          const newWindow = await fin.Window.create(options)
          newWindow
            .on('closed', () => {
              setWindowsInLocalStorage().catch(console.warn)
            })
            .catch(console.warn)
          await setWindowsInLocalStorage()
        }
      } catch (err) {
        console.error('Failed to create window: ', err)
      }
    },
    [isOpenFin, getTargetWindow, setWindowsInLocalStorage]
  )

  // ------------------------AUTOLAUNCH------------------------ //

  const autoLaunch = (
    startupParameters: any,
    interval: any,
    setStateInterval: any
  ) => {
    if (startupParameters.openfinAutoStart) {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
      setStateInterval(
        setInterval(() => {
          const now = new Date()
          const hour = now.getHours()
          const min = now.getMinutes()
          const sec = now.getSeconds()
          const startTime = startupParameters.openfinStartTime
          let startHour: number | undefined
          let startMin: number | undefined
          if (startTime) {
            const startTimeArr = startTime.split(':')
            if (startTimeArr.length === 2) {
              startHour = Number(startTimeArr[0])
              startMin = Number(startTimeArr[1])
            }
          }
          if (
            startHour &&
            startMin &&
            hour === startHour &&
            sec < 2 &&
            min === startMin
          ) {
            finWindowAction(null, 'restore')
          }
        }, 1000)
      )
    } else {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
    }
  }

  // ------------------------NOTIFICATIONS------------------------ //

  const notificationAction = useCallback(
    (event: Notifications.NotificationActionEvent) => {
      const { result } = event
      switch (result.task) {
        case 'view-details':
          const id = result.id.length === 6 ? result.id.substring(1) : result.id
          dispatch(setTradeConfirmId(id))
          break
        case 'popout-depth':
          if (!popoutIds.includes(result.id)) {
            dispatch(popoutSecurity(result.id))
          } else {
            finWindowAction(`Depth/${result.id}`, 'restore').catch(console.warn)
          }
          break
        case 'accept-order':
          // TODO: spotHedgePref comes back undefined
          // use default for now
          dispatch(confirmOrder(result.id, 'D'))
          break
        case 'reject-order':
          dispatch(rejectOrder(result.id))
          break
      }
    },
    [dispatch, popoutIds, finWindowAction]
  )

  const registerNotifications = useCallback(() => {
    dispatch(subscribeToAlerts())
    Notifications.register()
      .then(() => {
        return Notifications.addEventListener(
          'notification-action',
          notificationAction
        ).catch((err) => console.error(err))
      })
      .catch((err) => console.error(err))
  }, [notificationAction, dispatch])

  const deregisterNotifications = useCallback(() => {
    if (isOpenFin) {
      dispatch(unsubscribeFromAlerts())
      Notifications.deregister(fin.me.identity.uuid)
        .then(() => {
          return Notifications.removeEventListener(
            'notification-action',
            notificationAction
          ).then(() =>
            Promise.all([Notifications.hide(), Notifications.clearAll()])
          )
        })
        .catch((err) => console.error(err))
    }
  }, [isOpenFin, notificationAction, dispatch])

  return {
    autoLaunch,
    finWindowAction,
    toggleFrame,
    changeDimensions,
    openPlatformWindow,
    notificationAction,
    registerNotifications,
    deregisterNotifications,
    isOpenFin
  }
}
