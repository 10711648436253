import {
  faTimes,
  faUser,
  faWindowMinimize,
  faWindowRestore
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { faOctagon } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import bondsLogoLightTheme from '../../../assets/images/bonds_logo_black.png'
import bondsLogoDarkTheme from '../../../assets/images/logo_white_blue.png'
import ComponentFooter from '../../../components/ComponentFooter/ComponentFooter'
import modalStyles from '../../../components/GenericModal/modal.module.scss'
import MarketsClosed from '../../../components/MarketsClosed/MarketsClosed'
import UserSelector from '../../../components/UserSelector/UserSelector'
import config from '../../../config'
import useBroadcastChannel, {
  BroadcastMessage
} from '../../../helpers/hooks/useBroadcastChannel'
import { useOpenFin, WindowAction } from '../../../helpers/hooks/useOpenFin'
import { getUserName } from '../../../store/auth/selectors'
import { getListTradingTransactionId } from '../../../store/listTrading/selectors'
import { getMarketClosed } from '../../../store/market/selectors'
import { useAppSelector } from '../../../store/types'
import {
  getOpenfinParameters,
  getUseLightTheme
} from '../../../store/userPreferences/selectors'
import { cancelAllWatchListOrdersAction } from '../../../store/watchList/actions'
import { getWatchList } from '../../../store/watchList/selectors'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import styles from './titlebar.module.scss'

export interface Props {
  toggleMenu: (menu: string) => void
}

type Interval = ReturnType<typeof setInterval>

const Titlebar: FC<Props> = ({ toggleMenu }) => {
  const {
    autoLaunch,
    finWindowAction,
    toggleFrame,
    openPlatformWindow,
    registerNotifications,
    deregisterNotifications
  } = useOpenFin()
  const dispatch = useDispatch()
  const [showQuitConfirmation, setQuitConfirmation] = useState(false)
  const [interval, setStateInterval] = useState<Interval | undefined>(undefined)

  const finApp = fin.Application.getCurrentSync()

  const isAdmin = useAppSelector(getIsAdmin)
  const userName = useAppSelector(getUserName)
  const marketClosed = useAppSelector(getMarketClosed)
  const startupParameters = useAppSelector(getOpenfinParameters)
  const useLightTheme = useAppSelector(getUseLightTheme)
  const watchlists = useAppSelector(getWatchList)
  const watchlistTransactionId = useAppSelector(getListTradingTransactionId)
  const bondsLogoTheme = useLightTheme
    ? bondsLogoLightTheme
    : bondsLogoDarkTheme

  const handleMessage = (value: BroadcastMessage) => {
    dispatch(value.action)
  }
  useBroadcastChannel('broadcastchannel', handleMessage)

  useEffect(() => {
    // this is so a list trading window can be created
    // without CreateTradingList being the parent window
    const newList = watchlists?.find(
      (wl) => wl.transactionId === watchlistTransactionId
    )
    if (newList?.id) {
      openPlatformWindow(`ListTrading/${newList.id}`)
    }
  }, [watchlistTransactionId, watchlists, openPlatformWindow])

  useEffect(() => {
    finApp
      .on('run-requested', () => {
        finWindowAction(null, 'restore')
        registerNotifications()
      })
      .catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    // remove default frame
    toggleFrame(false)

    // on start, launch windows from previous session
    const openWindows = localStorage.getItem(`openfinOpenWindows`)?.split(',')
    if (openWindows) {
      try {
        openWindows.forEach((name: string) => {
          if (
            ![
              'Alert',
              'UserEditor',
              'Depth',
              'Watchlist/',
              'LastLook',
              'Aggressor'
            ].some((partialName) => name.includes(partialName))
          ) {
            openPlatformWindow(name)
          }
        })
      } catch (error) {
        console.error('Error opening windows: ' + error)
      }
    }
  }, [openPlatformWindow, toggleFrame])

  useEffect(() => {
    autoLaunch(startupParameters, interval, setStateInterval)
  }, [JSON.stringify(startupParameters)])

  const handleQuitConfirmation = () => {
    setQuitConfirmation(!showQuitConfirmation)
  }

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  const windowAction = useCallback((action: keyof WindowAction) => {
    finWindowAction(null, action)
    if (action === 'hide') {
      cancelAllOrders()
      setQuitConfirmation(false)
      deregisterNotifications()
    }
  }, [])

  return (
    <div className={styles.titlebarContainer}>
      <ReactModal
        className={modalStyles.wrapperModal}
        overlayClassName={modalStyles.overlay}
        isOpen={showQuitConfirmation}
      >
        <div className={modalStyles.confirmContent}>Close BondsPro?</div>
        <ComponentFooter
          onCancelClick={handleQuitConfirmation}
          onApplyClick={() => windowAction('hide')}
          submitText="YES"
        />
      </ReactModal>

      {!isAdmin && (
        <div className={styles.appTitle}>
          <img alt="bonds.com" src={bondsLogoTheme} />
        </div>
      )}

      {marketClosed && (
        <MarketsClosed className={styles.marketStatus} isAdmin={isAdmin} />
      )}

      {!marketClosed && isAdmin && (
        <div className={styles.selectorContainer}>
          <UserSelector />
        </div>
      )}
      <span className={styles.marketStatus}>{config.env}</span>

      <div className={styles.actions}>
        <div
          className={cx(styles.icon, styles.menu)}
          onClick={() => toggleMenu('User')}
          title="User menu"
        >
          {userName}
        </div>
        <div
          className={cx(styles.icon, styles.cancelIcon)}
          onClick={cancelAllOrders}
          title="Cancel All Orders"
        >
          <FontAwesomeIcon icon={faOctagon} />
        </div>
        {isAdmin && (
          <div
            className={styles.icon}
            onClick={() => toggleMenu('Admin')}
            title="Admin options"
          >
            <FontAwesomeIcon icon={faUser} />
          </div>
        )}
        <div
          className={cx(styles.icon, styles.menu)}
          onClick={() => toggleMenu('Client')}
          title="Menu"
        >
          Menu
        </div>

        <div
          className={styles.icon}
          onClick={() => windowAction('show')}
          title="Show All Windows"
        >
          <FontAwesomeIcon icon={faWindowRestore} />
        </div>

        <div
          className={styles.icon}
          onClick={() => windowAction('minimize')}
          title="Minimize All Windows"
        >
          <FontAwesomeIcon icon={faWindowMinimize} />
        </div>

        <div
          className={styles.icon}
          onClick={handleQuitConfirmation}
          title="Quit"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  )
}

export default Titlebar
