import React from 'react'
import { useParams } from 'react-router'
import UserEditorModal from '../../../components/UserSelector/UserEditorModal'
import { useOpenFin } from '../../../helpers/hooks/useOpenFin'

const OpenfinUserEditor = () => {
  const { id } = useParams()
  const { finWindowAction } = useOpenFin()
  return (
    <UserEditorModal
      closeFunc={() => {
        finWindowAction(`UserEditor/${id}`, 'close')
      }}
      userId={Number(id)}
      wrapModal
    />
  )
}

export default OpenfinUserEditor
