import React, { FC, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearLastQueryResult,
  runQuery
} from '../../../store/admin/query/actions'
import {
  getQueryError,
  getQueryResult,
  getWaitingForQuery
} from '../../../store/admin/query/selectors'
import {
  PlotType,
  Query,
  QueryParameterType,
  QueryParameterValue
} from '../../../store/admin/query/types'
import Checkbox from '../../Checkbox'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import GenericDropdownMenu from '../../GenericDropdownMenu/GenericDropdownMenu'
import HcCandlestick from './HighChartsCandlestick'
import HcLine from './HighChartsLine'
import QuerySearchBondsInput from './QuerySecuritySearch/SearchBondsInput'
import styles from './query.module.scss'
import FinHeaderButtons from '../../ComponentHeader/helpers'

interface Props {
  queries: Query[]
}

const QueryList: FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const lastQueryResult = useSelector(getQueryResult)
  const waitingForQuery = useSelector(getWaitingForQuery)
  const error = useSelector(getQueryError)
  const queries = props.queries
  const [init, setInit] = useState<boolean>(false)
  const [values, setValues] = useState<any>({ val: [] })
  const [selectedQuery, setSelectedQuery] = useState<string>(queries[0].name)
  const [options] = useState<string[]>(props.queries.map((q) => q.name))

  useEffect(() => {
    reinitializeValues('')
  }, [])

  const reinitializeValues = (queryName: string) => {
    const qName = queryName.length > 0 ? queryName : selectedQuery
    const q = queries.find((query) => query.name === qName)
    const arr: any[] = []
    if (q) {
      for (const p of q.parameters) {
        switch (p.type) {
          case QueryParameterType.Security:
            arr.push('')
            break
          case QueryParameterType.String:
            arr.push('')
            break
          case QueryParameterType.Int:
            arr.push(0)
            break
          case QueryParameterType.Date:
            const myDate = new Date()
            arr.push(
              new Date(
                myDate.getFullYear(),
                myDate.getMonth(),
                myDate.getDate()
              )
            )
            break
          case QueryParameterType.Boolean:
            arr.push(false)
            break
        }
      }
    }
    const newObj = { val: arr }
    setValues(newObj)
    setInit(true)
  }

  function handleChange(event: any) {
    const vals = [...values.val]

    if (typeof vals[this] === 'boolean') {
      vals[this] = event.target ? event.target.checked : event
    } else {
      vals[this] = event.target ? event.target.value : event
    }
    setValues({ val: vals })
  }

  function handleTextChange(st: string) {
    const vals = [...values.val]
    vals[this] = st
    setValues({ val: vals })
  }

  const changeQuery = (name: string) => {
    setSelectedQuery(name)
    reinitializeValues(name)
  }

  const poplateComponents = () => {
    const query = queries.find((q) => q.name === selectedQuery)
    const params = populateParameters(query!)
    return query && init ? <div>{params}</div> : <div></div>
  }

  const populateParameters = (q: Query) => {
    let tsx = <></>
    let i = 0
    for (const p of q.parameters) {
      switch (p.type) {
        case QueryParameterType.Security: {
          tsx = (
            <>
              {tsx}
              <label className={styles.componentLabel}>{p.name}:</label>
              <QuerySearchBondsInput
                search={values.val[i]}
                setSearch={handleTextChange.bind(i)}
              />
            </>
          )
          break
        }
        case QueryParameterType.String: {
          tsx = (
            <>
              {tsx}
              <label className={styles.componentLabel}>{p.name}:</label>
              <input
                type="text"
                className={styles.componentInput}
                id={p.name}
                onChange={handleChange.bind(i)}
                value={values.val[i]}
              />
            </>
          )
          break
        }
        case QueryParameterType.Int: {
          tsx = (
            <>
              {tsx}
              <label className={styles.componentLabel}>{p.name}:</label>
              <input
                type="text"
                className={styles.componentInput}
                onChange={handleChange.bind(i)}
                value={values.val[i]}
              />
            </>
          )
          break
        }
        case QueryParameterType.Date: {
          tsx = (
            <>
              {tsx}
              <label className={styles.componentLabel}>{p.name}:</label>
              <input
                className={styles.datePicker}
                type="date"
                value={values.val[i]}
                onChange={handleChange.bind(i)}
              />
            </>
          )
          break
        }
        case QueryParameterType.Boolean: {
          tsx = (
            <>
              {tsx}
              <div className={styles.CheckboxWrapper}>
                <Checkbox
                  locator={'allBondsCheck'}
                  checked={values.val[i]}
                  onChange={handleChange.bind(i)}
                  disabled={false}
                >
                  {p.name}
                </Checkbox>
              </div>
            </>
          )
        }
      }
      i++
    }
    return (
      <form className={styles.form} onSubmit={(event) => submitQuery(event)}>
        <fieldset className={styles.formInputs}>
          {tsx}
          <button className={styles.RunButton} type="submit">
            Run query
          </button>
        </fieldset>
        {waitingForQuery && <div>Running query...</div>}
        {/* -- Debugging table --{lastQueryResult.queryName === selectedQuery && (
          <div
            dangerouslySetInnerHTML={{ __html: lastQueryResult.rawData }}
          ></div>
        )}*/}
        {error ? (
          <label className={styles.errorMsg}>{error}</label>
        ) : lastQueryResult.data.length &&
          lastQueryResult.data[0].plotType === PlotType.Line ? (
          <HcLine queryResult={lastQueryResult} />
        ) : (
          <HcCandlestick queryResult={lastQueryResult} />
        )}
        {/* {selectedQuery === 'BondsProMarketsAndTrades' ? (
          <TradesAndMarketsQueryChart queryResult={lastQueryResult} />
        ) : selectedQuery === 'Price history' ? (
          <PriceDataQuery queryResult={lastQueryResult} />
        ) : (
          <QueryChart queryResult={lastQueryResult} />
        )}*/}
      </form>
    )
  }

  const submitQuery = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const q = queries.find((query) => query.name === selectedQuery)
    let i = 0
    const queryParams: QueryParameterValue[] = []
    if (q) {
      for (const p of q.parameters) {
        const qpv: QueryParameterValue = { name: p.name, value: values.val[i] }
        queryParams.push(qpv)
        i++
      }
    }
    dispatch(clearLastQueryResult())
    dispatch(runQuery(selectedQuery, queryParams))
  }

  return (
    <>
      <ComponentHeader
        title="Order/Trade History"
        headerButtons={<FinHeaderButtons title="QuerySelector" />}
      />
      <div className={styles.FormWrapper}>
        <GenericDropdownMenu
          selectedItem={selectedQuery}
          setSelectedItem={changeQuery}
          options={options}
          selectId={''}
          openRight={true}
          className={styles.dropdown}
          labelClassName={styles.dropdownLabel}
        />
        {poplateComponents()}
      </div>
    </>
  )
}

export default QueryList
