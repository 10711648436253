import { useCallback, useEffect, useMemo } from 'react'
import {
  ClosePopoutAction,
  UpdatePopoutsAction
} from '../../store/depthOfMarket/actions'
import { CreateListTradingListAction } from '../../store/listTrading/types'
import {
  RemoveGridAction,
  SetQueryFilterAction,
  ShowSecurityDetailsAction
} from '../../store/securities/actions'
import { useAppSelector } from '../../store/types'
import { getIsOpenFin } from '../../store/webSettings/selectors'

export type BroadcastMessage = {
  action:
    | SetQueryFilterAction
    | RemoveGridAction
    | ShowSecurityDetailsAction
    | ClosePopoutAction
    | CreateListTradingListAction
    | UpdatePopoutsAction
  data?: string
}

const useBroadcastChannel = (
  channelName: string,
  hasMessage?: (data: any) => void
) => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const channel = useMemo(
    () => (isOpenFin ? new BroadcastChannel(channelName) : null),
    [channelName, isOpenFin]
  )

  useEffect(() => {
    if (isOpenFin && channel && hasMessage) {
      const handleMessage = (event: MessageEvent) => {
        hasMessage(event.data)
      }
      channel.addEventListener('message', handleMessage)

      return () => {
        channel.removeEventListener('message', handleMessage)
      }
    }
  }, [isOpenFin, hasMessage, channel])

  useEffect(() => {
    return () => channel?.close()
  }, [channel])

  const sendMessage = useCallback(
    (message: BroadcastMessage) => {
      if (channel && isOpenFin) {
        channel.postMessage(message)
      }
    },
    [channel, isOpenFin]
  )

  return sendMessage
}

export default useBroadcastChannel
