import {
  faClose,
  faWindowMinimize
} from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useOpenFin } from '../../helpers/hooks/useOpenFin'
import styles from './ComponentHeader.module.scss'

const FinHeaderButtons = ({ title }: { title: string }) => {
  const { finWindowAction, isOpenFin } = useOpenFin()
  if (!isOpenFin) return <></>
  return (
    <>
      <div
        className={styles.icon}
        onClick={() => {
          finWindowAction(title, 'minimize')
        }}
        title="Minimize"
      >
        <FontAwesomeIcon icon={faWindowMinimize} />
      </div>
      <div
        className={styles.icon}
        onClick={() => {
          finWindowAction(title, 'close')
        }}
        title="Close"
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
    </>
  )
}
export default FinHeaderButtons
