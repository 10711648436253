import React, { FC } from 'react'
import { useOpenFin } from '../../../helpers/hooks/useOpenFin'
import AdminMenu from './AdminMenu'
import ClientMenu from './ClientMenu'
import styles from './menus.module.scss'
import UserMenu from './UserMenu'

export interface Props {
  menuName: string
  closeMenu: () => void
}

const LeftMenu: FC<Props> = ({ menuName, closeMenu }) => {
  const { openPlatformWindow } = useOpenFin()

  const getMenu = () => {
    switch (menuName) {
      case 'Admin':
        return (
          <AdminMenu
            closeMenu={closeMenu}
            handleNavigation={(name) => {
              openPlatformWindow(name)
            }}
          />
        )
      case 'Client':
        return (
          <ClientMenu
            closeMenu={closeMenu}
            handleNavigation={(name) => {
              openPlatformWindow(name)
            }}
          />
        )
      case 'User':
        return <UserMenu closeMenu={closeMenu} />
      default:
        return null
    }
  }
  return <div className={styles.menuWrapper}>{getMenu()}</div>
}

export default LeftMenu
