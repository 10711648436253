import {
  faArrowRightToBracket,
  faGear,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { useUserSession } from '../../../routing/SessionContext'
import { useAppSelector } from '../../../store/types'
import { getIsOpenFin } from '../../../store/webSettings/selectors'
import styles from './menus.module.scss'

export interface Props {
  closeMenu: () => void
}

const UserMenu: FC<Props> = ({ closeMenu }) => {
  const { handleSignout, editProfile } = useUserSession()
  const isOpenFin = useAppSelector(getIsOpenFin)
  return (
    <div className={isOpenFin ? styles.menuContainer : ''}>
      <div className={styles.menuSection}>
        User
        {isOpenFin && (
          <span onClick={closeMenu}>
            <FontAwesomeIcon icon={faTimes} title="Close Menu" />
          </span>
        )}
      </div>
      <div className={styles.optionsContainer}>
        <div className={styles.dropdownButton} onClick={editProfile}>
          <FontAwesomeIcon icon={faGear} />
          <span>Manage Profile</span>
        </div>
        <div className={styles.dropdownButton} onClick={handleSignout}>
          <FontAwesomeIcon icon={faArrowRightToBracket} />
          <span>Sign Out</span>
        </div>
      </div>
    </div>
  )
}

export default UserMenu
