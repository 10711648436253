import {
  faChevronDoubleLeft,
  faChevronDoubleRight
} from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { toggleActivityPanel } from '../../store/activity/actions'
import {
  getActivitiesGroups,
  getActivityPanelVisible
} from '../../store/activity/selectors'
import { Activity } from '../../store/activity/types'
import {
  subscribeToAlerts,
  unsubscribeFromAlerts
} from '../../store/alerts/actions'
import { setTradeConfirmId } from '../../store/order/actions'
import { getTradeConfirmId } from '../../store/order/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getIsOpenFin } from '../../store/webSettings/selectors'
import { setWindowFront } from '../../store/windows/actions'
import { getWindowFront } from '../../store/windows/selectors'
import ExpandableComponent from '../ExpandableComponent/ExpandableComponent'
import ExpandableSectionTitle from '../ExpandableComponent/ExpandableSectionTitle'
import ActivityCard from './ActivityCard'
import ActivityHeader from './ActivityHeader'
import styles from './ActivityWrapper.module.scss'
import TradeConfirm from './TradeConfirm/TradeConfirm'

const ActivityWrapper: FC = () => {
  const [initialRender, setInitialRender] = useState(true)
  const isOpenFin = useAppSelector(getIsOpenFin)

  const activitiesByGroup = useAppSelector(getActivitiesGroups)
  const activityPanelVisible = useAppSelector(getActivityPanelVisible)
  const windowFront = useAppSelector(getWindowFront)
  const tradeConfirmId = useAppSelector(getTradeConfirmId)

  const dispatch = useAppDispatch()

  const hasActivities =
    activitiesByGroup.older.length !== 0 ||
    activitiesByGroup.pending.length !== 0 ||
    activitiesByGroup.today.length !== 0 ||
    activitiesByGroup.yesterday.length !== 0 ||
    activitiesByGroup.twoDaysAgo.length !== 0

  useEffect(() => {
    dispatch(subscribeToAlerts())

    return () => {
      dispatch(unsubscribeFromAlerts())
    }
  }, [dispatch])

  useEffect(() => {
    if (initialRender && hasActivities) {
      dispatch(toggleActivityPanel())
    }
  }, [hasActivities])

  const handleTradeConfirmClick = useCallback((id: string | null) => {
    dispatch(setTradeConfirmId(id === null ? id : Number(id.replace('O', ''))))
  }, [])

  const expandPanel = useCallback(() => {
    if (activityPanelVisible) {
      return false
    } else {
      dispatch(toggleActivityPanel())
      setInitialRender(false)
    }
  }, [activityPanelVisible])

  const collapsePanel = useCallback(() => {
    if (!activityPanelVisible) {
      return false
    } else {
      dispatch(toggleActivityPanel())
      setInitialRender(false)
    }
  }, [activityPanelVisible])

  const moveWindowToFront = useCallback(() => {
    dispatch(setWindowFront('activity'))
  }, [])

  useEffect(() => {
    activityPanelVisible && moveWindowToFront()
  }, [activityPanelVisible])

  const expandableTitle =
    (title: string) =>
    (isExpanded: boolean, setExpanded: (expanded: boolean) => void) => {
      return (
        <ExpandableSectionTitle
          className={styles.title}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
        >
          {title}
        </ExpandableSectionTitle>
      )
    }

  const renderActivityList = (activities: Activity[], placeholder: string) => {
    if (activities.length === 0) {
      return <p className={styles.placeholder}>{placeholder}</p>
    }
    return (
      <ul>
        {activities.map((activity) => (
          <li key={`${activity.type}-${activity.id}`}>
            <ActivityCard
              activity={activity}
              handleTradeConfirmClick={handleTradeConfirmClick}
            />
          </li>
        ))}
      </ul>
    )
  }

  if (isOpenFin) {
    return (
      <>
        {tradeConfirmId && (
          <TradeConfirm
            tradeConfirmId={tradeConfirmId}
            handleTradeConfirmClick={handleTradeConfirmClick}
          />
        )}
      </>
    )
  }

  return (
    <div
      className={cx(activityPanelVisible || styles.hidden)}
      onClick={expandPanel}
    >
      <div
        className={cx(
          styles.activityWrapper,
          activityPanelVisible || styles.hidden,
          windowFront === 'depth' || styles.windowFront
        )}
        onClick={moveWindowToFront}
      >
        <div className={styles.activityWrapperHeader}>
          {activityPanelVisible && (
            <FontAwesomeIcon
              icon={faChevronDoubleRight}
              onClick={collapsePanel}
              title="Hide Activity Panel"
            />
          )}
          {!activityPanelVisible && (
            <FontAwesomeIcon
              icon={faChevronDoubleLeft}
              title="Show Activity Panel"
            />
          )}
        </div>

        <div className={styles.activityWrapperContent}>
          <ActivityHeader />

          <div className={styles.activityList}>
            <h2 className={styles.title}>Pending trades</h2>
            <div className={styles.activityListContent}>
              {renderActivityList(
                activitiesByGroup.pending,
                'No pending trades.'
              )}
            </div>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Today')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.today,
                  'No activity for today.'
                )}
              </div>
            </ExpandableComponent>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Yesterday')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.yesterday,
                  'No activity for yesterday.'
                )}
              </div>
            </ExpandableComponent>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Two days ago')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.twoDaysAgo,
                  'No activity for two days ago.'
                )}
              </div>
            </ExpandableComponent>
          </div>
        </div>
        {tradeConfirmId && (
          <TradeConfirm
            tradeConfirmId={tradeConfirmId}
            handleTradeConfirmClick={handleTradeConfirmClick}
          />
        )}
      </div>
    </div>
  )
}

export default memo(ActivityWrapper)
