import * as Notifications from '@openfin/workspace/notifications'
import { Activity } from '../../store/activity/types'

interface WindowConfig {
  windowHeight: number
  windowWidth: number
  isAdminWindow: boolean
}

export const openfinConfig: Record<string, WindowConfig> = {
  ActivityBlotter: {
    windowHeight: 500,
    windowWidth: 1000,
    isAdminWindow: false
  },
  ActivityLog: {
    windowHeight: 500,
    windowWidth: 1000,
    isAdminWindow: false
  },
  Aggressor: {
    windowHeight: 458,
    windowWidth: 400,
    isAdminWindow: false
  },
  AlertManager: {
    windowHeight: 635,
    windowWidth: 450,
    isAdminWindow: false
  },
  Benchmarks: {
    windowHeight: 85,
    windowWidth: 850,
    isAdminWindow: false
  },
  Depth: {
    windowHeight: 340,
    windowWidth: 543,
    isAdminWindow: false
  },
  LastLook: {
    windowHeight: 458,
    windowWidth: 400,
    isAdminWindow: false
  },
  ListTrading: {
    windowHeight: 500,
    windowWidth: 1500,
    isAdminWindow: false
  },
  PassiveOrders: {
    windowHeight: 500,
    windowWidth: 1030,
    isAdminWindow: false
  },
  Settings: {
    windowHeight: 500,
    windowWidth: 340,
    isAdminWindow: false
  },
  Watchlist: {
    windowHeight: 500,
    windowWidth: 985,
    isAdminWindow: false
  },
  // ADMIN WINDOWS
  ActivityTicker: {
    windowHeight: 500,
    windowWidth: 1090,
    isAdminWindow: true
  },
  AggressAttempts: {
    windowHeight: 500,
    windowWidth: 1030,
    isAdminWindow: true
  },
  APIUserData: {
    windowHeight: 500,
    windowWidth: 1030,
    isAdminWindow: true
  },
  BBMISIN: {
    windowHeight: 407,
    windowWidth: 500,
    isAdminWindow: true
  },
  ExternalLiquidityStats: {
    windowHeight: 500,
    windowWidth: 1030,
    isAdminWindow: true
  },
  GUIUserData: {
    windowHeight: 500,
    windowWidth: 1030,
    isAdminWindow: true
  },
  ManagementReports: {
    windowHeight: 500,
    windowWidth: 415,
    isAdminWindow: true
  },
  QuerySelector: {
    windowHeight: 500,
    windowWidth: 740,
    isAdminWindow: true
  },
  QueueStats: {
    windowHeight: 500,
    windowWidth: 735,
    isAdminWindow: true
  },
  RestrictedTrading: {
    windowHeight: 500,
    windowWidth: 700,
    isAdminWindow: true
  },
  RestrictedTradingPermanent: {
    windowHeight: 500,
    windowWidth: 700,
    isAdminWindow: true
  },
  SystemParameters: {
    windowHeight: 500,
    windowWidth: 1050,
    isAdminWindow: true
  },
  Tiers: {
    windowHeight: 500,
    windowWidth: 750,
    isAdminWindow: true
  },
  TradeBlotter: {
    windowHeight: 500,
    windowWidth: 900,
    isAdminWindow: true
  },
  UserDashboard: {
    windowHeight: 500,
    windowWidth: 900,
    isAdminWindow: true
  },
  UserEditor: {
    windowHeight: 500,
    windowWidth: 800,
    isAdminWindow: true
  },
  WatchlistManager: {
    windowHeight: 500,
    windowWidth: 750,
    isAdminWindow: true
  }
}

export const copyAppStyles = (window: Window) => {
  // Copy the app's styles into the new window
  const stylesheets = Array.from(document.styleSheets)
  stylesheets.forEach((stylesheet) => {
    /* tslint:disable */
    const css = stylesheet
    if (stylesheet.href) {
      const newStyleElement = document.createElement('link')
      newStyleElement.rel = 'stylesheet'
      newStyleElement.href = stylesheet.href
      window.document.head.appendChild(newStyleElement)
    } else if (css && css.cssRules && css.cssRules.length > 0) {
      const newStyleElement = document.createElement('style')
      Array.from(css.cssRules).forEach((rule) => {
        newStyleElement.appendChild(document.createTextNode(rule.cssText))
      })
      window.document.head.appendChild(newStyleElement)
    }
  })

  // Copy the theme class from the body element
  const originalBody = document.body
  const newBody = window.document.body

  newBody.className = originalBody.className
}

export const createOpenFinAlert = (alerts: Activity[]) => {
  alerts.forEach((alert) => {
    const getIndicatorColor = () => {
      if (['pendingTrade', 'trade', 'tradeAttempt'].includes(alert.type)) {
        return alert.cardColor === 'blue'
          ? Notifications.IndicatorColor.BLUE
          : Notifications.IndicatorColor.RED
      } else return Notifications.IndicatorColor.YELLOW
    }
    const getButtons = () => {
      const buttons = []
      if (alert.type === 'trade') {
        buttons.push({
          cta: true,
          title: 'Details',
          onClick: {
            task: 'view-details',
            id: alert.id
          }
        })
      }
      if (!alert.isPending && !alert.hasAggressorActions) {
        buttons.push({
          cta: true,
          title: 'Market',
          onClick: {
            task: 'popout-depth',
            id: alert.securityId
          }
        })
      }
      if (alert.isPending && alert.hasLastLookActions) {
        buttons.push(
          {
            cta: true,
            title: 'Reject',
            onClick: {
              task: 'reject-order',
              id: alert.id
            }
          },
          {
            cta: true,
            title: 'Accept',
            onClick: {
              task: 'accept-order',
              id: alert.id
            }
          }
        )
      }

      return buttons
    }
    const showNotification = async () => {
      await Notifications.create({
        platform: fin.me.identity.uuid,
        indicator: {
          color: getIndicatorColor(),
          text: alert.formattedTitle?.toString() ?? alert.title
        },
        title: alert.type === 'trade' ? alert.description : '',
        body:
          alert.type === 'trade' ? alert.badgeInfo ?? '' : alert.description,
        category: alert.type,
        sticky: alert.type === 'alert' ? 'transient' : 'sticky',
        id: alert.id,
        date: alert.date,
        expires: alert.expiration,
        allowReminder: false,
        buttons: getButtons()
      })
    }
    showNotification().catch((err) =>
      console.error('Failed to create notification', err)
    )
  })
}
