import React from 'react'
import Popout from '../../../containers/Popouts/Popout'
import { useAppSelector } from '../../../store/types'
import { getIsOpenFin } from '../../../store/webSettings/selectors'
import { foregroundAggressorWindowAtIndex } from '../../../store/windows/actions'
import {
  getActiveAggressorWindow,
  getAggressorWindows
} from '../../../store/windows/selectors'
import Wrapper from '../../Wrapper/Wrapper'
import AggressorModal from '../AggressorModal/AggressorModal'
import { useActiveWindow } from '../helpers'

const AggressorModalStack = () => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const aggressorWindows = useAppSelector(getAggressorWindows)
  const activeModalIndex = useAppSelector(getActiveAggressorWindow)
  const { handleMinimize } = useActiveWindow(
    aggressorWindows.length,
    activeModalIndex,
    foregroundAggressorWindowAtIndex
  )

  return aggressorWindows.length > 0 ? (
    <Wrapper>
      {aggressorWindows.map((windowInfo, i) =>
        isOpenFin ? (
          <Popout
            key={windowInfo.transactionId}
            title={`Aggressor/${windowInfo.transactionId}`}
            windowInfo={windowInfo}
          >
            <AggressorModal
              windowInfo={windowInfo}
              isActiveWindow={activeModalIndex === i}
              handleMinimize={handleMinimize}
              index={i}
            />
          </Popout>
        ) : (
          <AggressorModal
            key={windowInfo.initialOrderId + windowInfo.transactionId}
            windowInfo={windowInfo}
            isActiveWindow={activeModalIndex === i}
            handleMinimize={handleMinimize}
            index={i}
          />
        )
      )}
    </Wrapper>
  ) : null
}
export default AggressorModalStack
