import { useCallback } from 'react'
import { useAppSelector } from '../../store/types'
import { getIsOpenFin } from '../../store/webSettings/selectors'

export const useClipboard = () => {
  const isOpenFin = useAppSelector(getIsOpenFin)
  const writeClipboardText = useCallback(
    async (text: string) => {
      try {
        if (isOpenFin) {
          await fin.Clipboard.write({ data: { text } })
        } else {
          await navigator.clipboard.writeText(text)
        }
      } catch (error) {
        console.warn('Clipboard write failed:', error)
      }
    },
    [isOpenFin]
  )

  return writeClipboardText
}
